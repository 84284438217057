:root {
  font-size: 14px;
  background-color: #100f1a;
}

.height100 {
  height: 100%;
}

.padding10 {
  padding: 10px 0;
}

.extra-bold {
  font-weight: 700 !important;
}

.coin_list .MuiTypography-body2 span {
  font-size: 17px;
  font-weight: 600;
}

.coin_list .MuiTypography-body1 span {
  font-size: 13px;
  font-weight: 300;
}

.d-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.d-flex.justify-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.d-flex.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.d-flex.alignstart {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.d-flex.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.step_bar {
  height: 880px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  -webkit-box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.3);
  background-color: #302f35;
  -webkit-backdrop-filter: blur(42px);
  backdrop-filter: blur(42px);
}

.completed h5,
.activeStep h5 {
  width: 33px;
  height: 33px;
  background: #ffffff;
  position: absolute;
  z-index: 1;
  text-align: center;
  border-radius: 50%;
  left: -4px;
  font-size: 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.completed {
  height: 30px;
  width: 30px;
  border: 2px solid #ff2626;
  border-radius: 50%;
  margin: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  background-color: #fff;
}

.completed::before {
  content: " ";
  position: absolute;
  top: -200px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 200px;
  width: 2px;
  background: #ff2626;
}

.activeStep {
  height: 30px;
  width: 30px;
  border: 2px solid #0d2913;
  border-radius: 50%;
  margin: 0 15px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: relative;
  background-color: #fff;
  z-index: 999;
}

body {
  margin: 0;
  padding: 0;
  background: #fafafa;
  color: #00ce78;
}

.left-nav .MuiButton-label {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.page-heading {
  font-weight: 300 !important;
  font-size: 36px !important;
  line-height: 54px;
}

.page-heading2 {
  font-weight: 400 !important;
  font-size: 30px !important;
}

.text-white {
  color: #fff;
  width: 100px;
  height: 52px;
  border-radius: 5px;
  background: transparent;
  border: 1px solid white;
}

.customForm {
  min-width: 500px;
  max-width: 500px;
  margin: 0 auto;
  padding: 30px;
  padding-bottom: 14px;
  border-radius: 5px;
  border: 1px solid #797979;
}

@media (max-width: 375px) {
  .customForm {
    min-width: 50px;
  }
}

.customForm.MuiTextField-root {
  margin: 20px 0;
}

.customFormh5 {
  margin: 10px 0;
}

.rightButton {
  float: right;
}

.leftButton {
  float: left;
}

.dzu-dropzone {
  overflow: auto !important;
}

.rightPosition {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  right: 20px;
}

.notication-list a {
  color: #000;
}

.width120 {
  width: calc(100% - 150px);
}

.ellispsys {
  text-overflow: ellipsis;
  overflow: hidden;
  display: block;
  white-space: pre;
  width: calc(100% - 30px);
}

@media screen and (max-width: 768px) {
  .step_bar {
    height: 501px;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.1rem 0.7rem rgba(0, 0, 0, 0.1);
  }
  .width120 {
    width: 100%;
    margin-bottom: 20px;
  }
  .rightPosition {
    position: absolute;
    top: auto;
    bottom: -5px;
    right: auto;
    left: 55px;
  }
}

.side_nev_Bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.loginForm {
  /* width: 80%; */
  /* max-width: 600px; */
}

.fullwidth {
  width: 100%;
}

.faqBg {
  height: 150px;
  background: url("https://dummyimage.com/600x400/00ab99/00ab99");
  border-radius: 12px;
}

.tradebox {
  background: #302f35;
  padding: 20px 40px 118px 40px;
  border-radius: 40px;
  margin-top: 40px;
}

@media (max-width: 375px) {
  .tradebox {
    padding: 20px 0px 53px 0px;
    border-radius: 16px;
  }
}

.walletbox {
  /* padding: 10px; */
  border: 1px solid white;
}

.bankbox {
  /* background-image: linear-gradient(rgb(58, 58, 58), #362e9e); */
  padding: 20px 40px 80px 40px;
  border-radius: 40px;
  margin-top: 40px;
}

@media (max-width: 768px) {
  .bankbox {
    padding: 20px 7px 80px 7px;
    border-radius: 16px;
  }
}

.notificationbox h5 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.termcondition h4 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 30px;
}

.termcondition h5 {
  font-size: 20px;
  font-weight: 600;
  color: #44edd6;
  line-height: 24px;
}

.termcondition p {
  margin-bottom: 20px;
  line-height: 21px;
}

.signupbox {
  padding: 40px 40px 40px 40px;
  border-radius: 40px;
  -webkit-box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.3);
  /* background-color: #302f35; */
  -webkit-backdrop-filter: blur(42px);
  backdrop-filter: blur(42px);
}

.signupbox h5 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 10px;
}

.signupbox p {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  /* padding-bottom: 10px; */
}

.signupbox a {
  color: #44edd6;
  text-decoration: none;
}

@media (max-width: 375px) {
  .signupbox {
    padding: 20px 6px 20px 5px;
  }
}

.forgetbox {
  background: #000;
  color: #fff;
}

.forgetbox a {
  color: #44edd6;
}

.resetbox {
  background-color: #000;
}

.resethead {
  padding: 10px 40px 50px 40px;
  border-radius: 40px;
  -webkit-box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 30px 30px rgba(0, 0, 0, 0.3);
  background-color: #302f35;
  -webkit-backdrop-filter: blur(42px);
  backdrop-filter: blur(42px);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.resethead.justify-space-between {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.resethead.justify-start {
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.resethead.alignstart {
  -webkit-box-align: start !important;
  -ms-flex-align: start !important;
  align-items: flex-start !important;
}

.resethead.justify-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

@media (max-width: 768px) {
  .resethead {
    padding: 10px 7px 50px 7px;
  }
}

.contactbox h6 {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.emailbox a {
  color: #fff;
  font-family: Roboto, sans-serif;
  text-decoration: none;
}

.uploadbox h6 {
  color: #fff;
  font-size: 12px;
  font-weight: 600;
}

.hexa {
  -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  -moz-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  -ms-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
}

.gon {
  width: 140px;
  height: 152px;
  background: #ffffff94;
  background-size: 220px 220px;
  line-height: 146px;
  text-align: center;
  font-size: 23px;
  font-weight: bold;
  letter-spacing: 2px;
  color: #ff1919;
  cursor: pointer;
  -webkit-transition: color 500ms ease, text-shadow 500ms ease;
  transition: color 500ms ease, text-shadow 500ms ease;
}

.hex {
  margin-top: 30px;
  width: 104px;
  height: 60px;
  background-color: red;
  border-color: red;
  position: relative;
  display: inline-block;
}

.hex:before {
  content: " ";
  width: 0;
  height: 0;
  border-bottom: 30px solid;
  border-color: inherit;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
  position: absolute;
  top: -30px;
}

.hex:after {
  content: "";
  width: 0;
  position: absolute;
  bottom: -30px;
  border-top: 30px solid;
  border-color: inherit;
  border-left: 52px solid transparent;
  border-right: 52px solid transparent;
}

.text-center {
  text-align: center !important;
}

a {
  color: #fff;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  display: inline-block;
  text-decoration: none;
}

h5,
h6 {
  color: #fff;
  font-family: "Barlow", sans-serif;
  font-weight: 700;
  margin: initial;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.roadmap-wrap {
  position: relative;
  padding: 40px 0 10px;
  margin-bottom: 40px;
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
}

.roadmap-wrap {
  position: relative;
  padding: 40px 0 10px;
  margin-bottom: 40px;
  max-width: 430px;
  margin-left: auto;
  margin-right: auto;
}

.roadmap-wrap:before,
.roadmap-wrap:after {
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  z-index: 7;
}

.roadmap-line {
  position: absolute;
  left: 19px;
  top: 0;
  background: #c1cef1;
  height: 100%;
  width: 2px;
  content: "";
  z-index: 9;
}

.roadmap-line:before {
  top: 0;
}

.roadmap-line:after,
.roadmap-line:before {
  position: absolute;
  background: #c1cef1;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  content: "";
  left: -4px;
}

.roadmap-line:after {
  bottom: 0;
}

.roadmap-line:after,
.roadmap-line:before {
  position: absolute;
  background: #c1cef1;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  content: "";
  left: -4px;
}

.roadmap {
  position: relative;
  z-index: 10;
  padding-bottom: 30px;
}

.roadmap-year {
  border-radius: 50%;
  color: #fff;
  background: #e41313;
  height: 50px;
  width: 50px;
  font-family: "Roboto", "Helvetica", "Arial";
  text-align: center;
  line-height: 50px;
  font-weight: 500;
  font-size: 12px;
  position: relative;
  left: -5px;
}

.roadmap {
  position: relative;
  z-index: 10;
  padding-bottom: 30px;
}

.roadmap {
  position: relative;
  z-index: 10;
  padding-bottom: 30px;
}

.bg-light .roadmap-step {
  background: #fff;
}

.roadmap-step {
  border: 1px solid transparent;
  border-color: rgba(255, 255, 255, 0.2);
  border-radius: 40px;
  position: relative;
  margin-left: 50px;
  padding: 20px 20px 15px;
  position: relative;
  max-width: 380px;
}

@media (max-width: 768px) {
  .roadmap-step {
    border-radius: 16px;
  }
}

.bg-light .roadmap-step:before {
  border-color: #f6fafd;
}

.roadmap-step:before {
  height: 18px;
  width: 18px;
  border-radius: 50%;
  border: 5px solid #fff;
  background: #ff1919;
  left: -41px;
  top: 29px;
  z-index: 1;
}

.roadmap-step:before,
.roadmap-step:after {
  position: absolute;
  content: "";
}

.roadmap-head {
  margin-bottom: 10px;
  padding-right: 35px;
  position: relative;
}

.roadmap-time {
  display: block;
  color: #122272;
  font-size: 13px;
  text-transform: uppercase;
}

.roadmap-title {
  color: #8ca2e5;
  font-weight: 700;
  font-size: 16px;
}

.bg-light .roadmap-step:before {
  border-color: #f6fafd;
}

.roadmap-step p:last-child {
  margin-bottom: 0;
}

.roadmap-step:after {
  height: 2px;
  width: 22px;
  background: #dbe4f7;
  left: -22px;
  top: 42px;
}

.roadmap-step:before,
.roadmap-step:after {
  position: absolute;
  content: "";
}

.roadmap-wrap {
  padding: 40px 0 10px;
  margin-bottom: 60px;
  max-width: 100%;
}

.list-dot li {
  position: relative;
  padding-left: 22px;
  line-height: 1.62;
}

.list li {
  line-height: 1.44;
  padding: 5px 0;
  position: relative;
  color: #415076;
  font-size: 14px;
}

.list-dot li:before {
  position: absolute;
  top: 14px;
  left: 0;
  height: 5px;
  width: 5px;
  content: "";
  border-radius: 50%;
  background: #1940b0;
}

@media (min-width: 1200px) {
  .roadmap-left .roadmap-step:after {
    right: -73px;
  }
  .roadmap-step:after {
    width: 73px;
    left: -73px;
  }
  .roadmap-step:before {
    left: -91px;
  }
  .roadmap-left .roadmap-step:before {
    right: -91px;
  }
}

@media (min-width: 992px) {
  .roadmap-left .roadmap-step:before {
    left: auto;
    right: -91px;
  }
  .roadmap-left .roadmap-step:after {
    left: auto;
    right: -74px;
  }
  .roadmap-step:after {
    width: 73px;
    left: -73px;
  }
  .roadmap-left .roadmap-step {
    margin-left: 0;
    margin-right: 80px;
  }
  .roadmap-left {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-top: -80px;
  }
  .roadmap-right .roadmap-step {
    margin-left: 80px;
  }
  .roadmap-head {
    margin-bottom: 15px;
  }
  .roadmap-wrap {
    padding: 40px 0 10px;
    margin-bottom: 60px;
    max-width: 100%;
  }
  .roadmap-line {
    left: 50%;
    margin-left: -1px;
  }
  .roadmap {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .roadmap-year {
    height: 60px;
    width: 60px;
    line-height: 60px;
    font-size: 18px;
    position: static;
    margin-left: auto;
    margin-right: auto;
  }
  .roadmap-right {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .roadmap-right .roadmap-step {
    margin-left: 80px;
  }
  .roadmap-left {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    margin-top: -80px;
  }
  .roadmap-left .roadmap-step {
    margin-left: 0;
    margin-right: 80px;
  }
  .roadmap-left .roadmap-step:before {
    right: -84px;
  }
  .roadmap-head {
    margin-bottom: 15px;
  }
  .roadmap-step {
    width: 100%;
    padding: 20px 30px 20px;
  }
  .roadmap-step:before {
    left: -84px;
  }
}

.navigation p {
  margin: 0 5px;
  font-size: 15px;
  padding: 4px 10px;
  cursor: pointer;
  color: #fff;
}

.hovertext.active,
.hovertext:hover {
  color: red;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.home-banner {
  background-position: bottom center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  z-index: 1;
  padding: 150px 0 80px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

.home-banner h1 {
  font-size: 50px;
  margin-bottom: 20px;
  font-weight: 700;
}

.white-text {
  color: #fff;
}

.circle1 {
  position: absolute;
  left: 40px;
  top: 40px;
  width: 100px;
  height: 100px;
  border-radius: 5px;
  background: rgba(255, 255, 255, 0.1);
  -webkit-animation: move 2.5s linear infinite;
  animation: move 2.5s linear infinite;
}

.circle2 {
  position: absolute;
  left: 30%;
  top: 60%;
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  -webkit-animation: move 3s linear infinite;
  animation: move 3s linear infinite;
}

.circle3 {
  position: absolute;
  right: 40px;
  top: 140px;
  width: 130px;
  height: 130px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  -webkit-animation: move 2.5s linear infinite;
  animation: move 2.5s linear infinite;
}

.circle4 {
  position: absolute;
  left: 45%;
  top: 50px;
  width: 160px;
  height: 160px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  -webkit-animation: move 3.5s linear infinite;
  animation: move 3.5s linear infinite;
}

.hoverimage {
  -webkit-animation: move 3.5s linear infinite;
  animation: move 3.5s linear infinite;
}

@-webkit-keyframes move {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

@keyframes move {
  0% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
  50% {
    -webkit-transform: translateY(25px);
    transform: translateY(25px);
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
  }
}

.single-features {
  background-color: #000000;
  padding: 30px;
  position: relative;
  z-index: 1;
  z-index: 1;
}

.single-features:hover {
  color: #ffffff;
}

.single-features::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0%;
  height: 100%;
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#00ae99),
    to(#00ae99)
  );
  background-image: linear-gradient(to bottom right, #00ae99, #00ae99);
  z-index: -1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.single-features:hover::after {
  left: 0;
  right: auto;
  width: 100%;
  border-radius: 10px;
}

.single-features::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 3px;
  height: 100%;
  background-color: #e33224;
  z-index: -1;
}

.single-features h3 {
  color: #ffffff;
  position: relative;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.timeline {
  height: 500px;
}

.timeline .slide {
  position: relative;
  display: inline-block;
  list-style-type: none;
  width: 160px;
  height: 3px;
  background: #000;
  top: 200px;
  outline: none;
}

.timeline .slide::before {
  width: 20px;
  height: 20px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: 50%;
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  content: "";
}

.timeline .slide::after {
  width: 3px;
  height: 80px;
  background: #000;
  position: absolute;
  top: -80px;
  left: 50%;
  -webkit-transform: translateX(-2px);
  transform: translateX(-2px);
  content: "";
}

.timeline .slide .timeline-box {
  width: 100%;
  height: 100px;
  position: absolute;
  top: -180px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-color: #00e0b0;
  color: #fff;
  border-radius: 10px;
  padding: 20px;
  -webkit-box-shadow: 0 0 10px rgba(165, 165, 165, 0.2);
  -ms-box-shadow: 0 0 10px rgba(165, 165, 165, 0.2);
  box-shadow: 0 0 10px rgba(165, 165, 165, 0.2);
  margin: 0 0 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.timeline .slide .timeline-box:hover {
  color: #ffffff;
}

.timeline .slide .timeline-box::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 0%;
  height: 100%;
  border-radius: 10px;
  background-image: -webkit-gradient(
    linear,
    left top,
    right bottom,
    from(#00ae99),
    to(#00ae99)
  );
  background-image: linear-gradient(to bottom right, #00ae99, #00ae99);
  z-index: -1;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}

.timeline .slide .timeline-box:hover::after {
  left: 0;
  right: auto;
  width: 100%;
  border-radius: 10px;
}

.timeline .slide:last-child {
  width: 280px;
}

.timeline .slide:not(:first-child) {
  margin-left: 0;
}

.slick-slide:first-child {
  height: 500px;
}

.slick-slide:nth-child(even) .slide {
  position: relative;
  display: inline-block;
  list-style-type: none;
  width: 160px;
  height: 3px;
  background: #000;
  top: 200px;
}

.slick-slide:nth-child(even) .slide::before {
  width: 20px;
  height: 20px;
  background: #000;
  border-radius: 50%;
  position: absolute;
  top: -10px;
  left: 50%;
  -webkit-transform: translateX(-10px);
  transform: translateX(-10px);
  content: "";
}

.slick-slide:nth-child(even) .slide::after {
  top: 0px;
}

.slick-slide:nth-child(even) .slide .timeline-box {
  top: 80px;
}

.slick-prev,
.slick-next {
  width: 34px !important;
  height: 34px !important;
  background: #ffffff !important;
  -webkit-box-shadow: 0px 4px 57px rgba(0, 0, 0, 0.25) !important;
  box-shadow: 0px 4px 57px rgba(0, 0, 0, 0.25) !important;
  border-radius: 50%;
  z-index: 1;
  -webkit-transform: translate(0, -22%) !important;
  transform: translate(0, -22%) !important;
}

.slick-next {
  right: 0 !important;
}

.slick-prev {
  left: 0 !important;
}

.slick-prev:before,
.slick-next:before {
  position: absolute;
  left: 14px;
  top: 5px;
}

.slick-next:before {
  content: url("arrow-right.svg") !important;
}

.slick-prev:before {
  content: url("arrow-left.svg") !important;
  left: 12px;
}

.icon-Box {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.single-security {
  padding: 30px;
  -webkit-box-shadow: 0 0 10px rgba(165, 165, 165, 0.2);
  -ms-box-shadow: 0 0 10px rgba(165, 165, 165, 0.2);
  box-shadow: 0 0 10px rgba(165, 165, 165, 0.2);
  margin: 0 0 30px;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.5s !important;
  transition: all 0.5s !important;
}

.single-security .icon-Box {
  -webkit-transition: all 0.5s !important;
  transition: all 0.5s !important;
}

.single-security:hover {
  -webkit-transform: translateY(-5px) !important;
  transform: translateY(-5px) !important;
}

.single-security:hover .icon-Box {
  -webkit-transform: rotateY(360deg);
  transform: rotateY(360deg);
}

.approach-content {
  -webkit-transition-delay: initial !important;
  transition-delay: initial !important;
  -webkit-transition: all 0.5s !important;
  transition: all 0.5s !important;
}

.approach-content:hover {
  -webkit-transform: translateY(-5px) !important;
  transform: translateY(-5px) !important;
}

.approach-content .icon-Box {
  -webkit-transition: all 0.5s !important;
  transition: all 0.5s !important;
}

.approach-content:hover .icon-Box {
  -webkit-transform: rotateY(360deg);
  transform: rotateY(360deg);
}

.team {
  -webkit-transition: all 0.5s !important;
  transition: all 0.5s !important;
  padding: 20px;
  text-align: center;
}

.team:hover {
  -webkit-transform: translateY(-5px) !important;
  transform: translateY(-5px) !important;
}

.headerNav {
  width: 100%;
  border-bottom: 10px solid #222;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0px 100px 0;
  height: 80px;
  background: #f8f8f8;
}

.logo a {
  padding-top: 33px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  clear: both;
  padding-bottom: 30px;
  text-decoration: none;
}

.logo a p {
  width: 500px;
  display: block;
}

.logo a em {
  font-size: 0.5em;
  float: left;
  display: block;
}

.logo a em img {
  display: inline-block;
  margin-top: 5px;
  width: 15px;
  float: left;
}

.logo a em .letterhead {
  display: inline-block;
  line-height: 260%;
  float: left;
}

.gray {
  color: #ccc;
}

a {
  color: #222;
  opacity: 1;
  -webkit-transition: all 0.6s;
  transition: all 0.6s;
  color: #222;
  font-size: 1em;
}

a:hover {
  opacity: 1;
}

.fa-bars {
  display: none;
  color: #222;
  font-size: 2rem;
}

nav ul {
  display: -webkit-box;
  display: -ms-flexbox;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
}

nav li {
  margin: 0 15px;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  font-size: 1em;
}

nav a {
  font-size: 1em;
  text-decoration: none;
}

nav a .active {
  color: tomato;
}

nav a.active {
  color: #222;
}

.footerHome {
  background-color: #000;
}

.text-center {
  text-align: center;
}

.justify-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.navigation p {
  margin: 0 5px;
  font-size: 15px;
  padding: 4px 10px;
  cursor: pointer;
  color: #fff;
}

.navigation .loginButton {
  background-color: #00e0b0;
  color: #fff;
  padding: 4px 15px;
  border-radius: 4px;
}

.deskmenu p {
  margin: 0 1px !important;
}

nav {
  background: transparent;
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: center;
}

label.logo {
  color: white;
  font-size: 35px;
  line-height: 80px;
  font-weight: bold;
}

nav ul {
  float: right;
  margin-right: 20px;
  padding: 0;
}
@media (max-width: 952px) {
  nav ul {
    float: none;
    margin-right: 20px;
    padding: 0;
  }
}

nav ul li {
  display: inline-block;
  margin: 5px 0px;
}

nav ul li a {
  color: white;
  font-size: 17px;
  padding: 7px 13px;
  border-radius: 3px;
  text-transform: uppercase;
}

/* a.active,
a:hover {
  background: #1b9bff;
  -webkit-transition: 0.5s;
  transition: 0.5s;
} */

.checkbtn {
  font-size: 30px;
  color: white;
  float: right;
  line-height: 80px;
  margin-right: 40px;
  cursor: pointer;
  display: none;
}

#check {
  display: none;
}

@media (max-width: 952px) {
  label.logo {
    font-size: 30px;
    padding-left: 20px;
    padding-top: 20px;
  }
  nav ul li a {
    font-size: 16px;
  }
}

.navbar {
  position: fixed;
  top: 0;
  z-index: 999;
  width: 100%;
  background: #000;
  height: 65px;
}

.sectionPadding {
  padding: 70px 0;
}

@media (max-width: 858px) {
  .sectionPadding {
    padding: 40px 0;
  }
  .home-banner {
    padding: 70px 0 70px;
  }
  .home-banner h1 {
    font-size: 35px;
    margin-bottom: 20px;
    font-weight: 700;
  }
  .navigation .loginButton {
    background-color: #f8fbfa;
    color: #00e0b0;
    padding: 4px 15px;
    border-radius: 4px;
    display: inline-block;
    min-width: 134px;
  }
  .navbar {
    position: relative;
    z-index: 999;
  }

  .navbar ul {
    position: absolute;
    width: calc(100% - 40px);
    height: auto;
    background: #000;
    display: block !important;
    margin: 0 !important;
    left: -108%;
    text-align: center;
    -webkit-transition: all 0.5s;
    transition: all 0.5s;
    padding: 35px 0 !important;
    -webkit-box-shadow: 0 11px 24px rgba(0, 0, 0, 0.12);
    box-shadow: 0 11px 24px rgba(0, 0, 0, 0.15);
  }
  nav ul li {
    display: block;
    margin: 10px 0;
    line-height: 30px;
  }
  nav ul li a {
    font-size: 20px;
  }
  a:hover,
  a.active {
    background: none;
    color: #0082e6;
  }
  #check:checked ~ ul {
    left: 10px;
    z-index: 99;
    height: 100vh !important;
    width: 90%;
  }

  .navigation p {
    color: #fff;
    width: 100%;
    display: flex;
    font-size: 16px;
    font-style: normal;
    align-items: flex-start;
    line-height: 24px;
    letter-spacing: -0.6px;
    justify-content: flex-start;
  }
}
@media (max-width: 1219px) {
  nav ul li {
    display: block;
    margin: 10px 0;
    line-height: 30px;
  }
  nav ul {
    float: left;
  }
  .checkbtn {
    display: block;
  }
}

/*# sourceMappingURL=main.css.map */

.boxsmall2 {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  background: linear-gradient(220.89deg, #ff19195e 42.01%, #ffffff42 82.75%);
  position: absolute;
  bottom: 100px;
  left: 30%;
  animation: moveOne 5s linear infinite;
}
.boxsmall4 {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: linear-gradient(220.89deg, #ff19195e 42.01%, #ffffff42 82.75%);
  box-shadow: 0px 4px 4px rgb(0 0 0 / 25%);
  position: absolute;
  top: 143px;
  left: 40%;
  animation: moveOne 5s linear infinite;
}

.boxsmall3 {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  background: linear-gradient(220.89deg, #ff19195e 42.01%, #ffffff42 82.75%);
  position: absolute;
  bottom: 218px;
  left: 35%;
  -webkit-animation: move 5s linear infinite;
  animation: move 5s linear infinite;
}

@-webkit-keyframes move {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes move {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateY(-20px);
    transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@-webkit-keyframes moveOne {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

@keyframes moveOne {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  50% {
    -webkit-transform: translateX(-20px);
    transform: translateX(-20px);
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}
input:-webkit-autofill {
  -webkit-background-clip: text !important;
  -webkit-text-fill-color: transparent !important;
}
.dzu-dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 200px !important;
  overflow: scroll;
  margin: 0 auto;
  position: relative;
  box-sizing: border-box;
  transition: all 0.15s linear;
  border: 2px solid #d9d9d9;
  border-radius: 24px !important;
  background: #302f35 !important;
}
.dzu-previewContainer {
  padding: 40px 3%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  min-height: 200px !important;
  z-index: 1;
  border-bottom: 1px solid #ececec;
  box-sizing: border-box;
}
.dzu-previewImage {
  width: auto;
  max-height: 112px !important;
  max-width: 164px !important;
  border-radius: 4px;
}
.dzu-previewButton {
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  width: 14px;
  height: 14px;
  cursor: pointer;
  opacity: 0.9;
  margin: 0 0 2px 10px;
  background: white;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.react-tel-input .country-list {
  outline: none;
  z-index: 1;
  list-style: none;
  position: absolute;
  padding: 0;
  margin: 10px 0 10px -1px;
  box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
  /* background-color: white; */
  width: 300px;
  max-height: 200px;
  overflow-y: scroll;
  border-radius: 0 0 3px 3px;
  background: url(/images/background.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: #000 !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: #000 !important;
  border-radius: 3px 0 0 0;
}
.react-tel-input .country-list .country:hover {
  background-color: #000 !important;
}
.react-tel-input .country-list .country.highlight {
  background-color: #000 !important;
}
.displyBox {
  display: flex;
  justify-content: space-between;
}
.buttonstyle {
  border: 1px solid white !important;
  padding: 10px !important;
  height: 52px;
  background: transparent;
  color: white;
  border-radius: 10px;
  /* width: 80px; */
}
.meniitems {
  padding: 10px !important;
  background-image: url("/images/background.png");
}
.selectBarField {
  border: 1px solid #fff;

  color: #fff;
  border-radius: 5px;
  border: 1px solid white;
  height: 52px;
  padding: 10px;
}
.addbutton {
  width: 60px;
  border: 1px solid white !important;
  padding: 10px !important;
  width: 60;
  background: transparent;
  color: white;
  border-radius: 5px;
}
.updateButton {
  border: 1px solid white !important;
  padding: 10px 20px !important;

  height: 52px;
  background: transparent;
  color: white;
  border-radius: 10px;
}
.dialogproperties {
  background-image: url("/images/background.png") !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border: 1px solid white !important;
}
.viewtypography {
  font-size: 16px !important;
  font-weight: 500 !important;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
  cursor: pointer;
}
input:focus {
  outline: none !important;
}
a,
a:hover,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

/* input::placeholder {
  font-weight: bold;
  opacity: 0.5;
  color: red;
} */
